import React from 'react';
import './home.scss'

const Home = () => {
  return(
    <div id="home">
      <h2>ALEJANDRO MORALES</h2>
      <h1>FULLSTACK DEVELOPER</h1>
    </div>
  );
}

export default Home;