import React from 'react';

const Footer = () => {
  return(
    <div className="footer">
     <svg width="143" height="35" viewBox="0 0 143 35" fill="none" xmlns="http://www.w3.org/2000/svg">
       <a href="https://github.com/alexmoralex" target="_blank" rel="noopener noreferrer">
        <path fillRule="evenodd" clipRule="evenodd" d="M129.275 34.3137C136.761 32.4423 142.314 25.5837 142.314 17.4092C142.314 7.79435 134.632 0 125.157 0C115.681 0 108 7.79435 108 17.4092C108 25.5837 113.552 32.4424 121.039 34.3137V29.7117H119.367C117.347 29.7117 115.49 28.6011 114.535 26.8213L114.325 26.4309C113.931 25.6956 113.32 25.0992 112.575 24.7223C112.113 24.3704 112.485 23.6404 113.041 23.8075L114.95 24.381C115.67 24.5971 116.255 25.1235 116.545 25.8161C116.937 26.7499 117.851 27.3575 118.863 27.3575H121.039V27.3298C121.039 26.673 121.193 26.052 121.467 25.501C117.507 25.1402 114.405 21.811 114.405 17.7573C114.405 16.1091 114.918 14.5806 115.793 13.3227L115.768 13.255C115.156 11.6062 115.261 9.76847 116.058 8.19266L116.348 8.20485C117.828 8.26711 119.167 9.06051 119.944 10.308C120.653 10.0954 121.404 9.98124 122.181 9.98124H128.59C129.686 9.98124 130.728 10.2078 131.674 10.6167C132.611 9.41725 134.092 8.77521 135.611 8.92838L135.907 8.95819C136.478 10.6281 136.312 12.4633 135.452 13.9949L135.424 14.0448C136.025 15.1481 136.366 16.4129 136.366 17.7573C136.366 21.9613 133.03 25.3861 128.861 25.5288C129.126 26.0728 129.275 26.684 129.275 27.3298V34.3137ZM125.122 34.8183L125.157 34.8183L125.191 34.8183H125.122Z" />
       </a>
       <a href="https://codepen.io/alexmoralex" target="_blank" rel="noopener noreferrer">
        <path fillRule="evenodd" clipRule="evenodd" d="M88.3137 17.8431C88.3137 27.3186 80.6323 35 71.1569 35C61.6814 35 54 27.3186 54 17.8431C54 8.36767 61.6814 0.686279 71.1569 0.686279C80.6323 0.686279 88.3137 8.36767 88.3137 17.8431ZM68.7199 17.7691L71.1569 19.6018L73.594 17.7691L71.1569 15.9365L68.7199 17.7691ZM63.9244 17.7691L62.3627 16.5948V18.9435L63.9244 17.7691ZM69.6587 6.78842L61.0448 13.2658C60.9851 13.2586 60.9243 13.2549 60.8627 13.2549C60.0343 13.2549 59.3627 13.9264 59.3627 14.7549V20.9313C59.3627 21.7598 60.0343 22.4313 60.8627 22.4313C60.9802 22.4313 61.0945 22.4178 61.2042 22.3923L69.6847 28.7694C69.8195 29.4595 70.4274 29.9804 71.1569 29.9804C71.8865 29.9804 72.4944 29.4596 72.6291 28.7695L81.1096 22.3923C81.2193 22.4179 81.3336 22.4314 81.451 22.4314C82.2794 22.4314 82.951 21.7598 82.951 20.9314V14.5833C82.951 13.7549 82.2794 13.0833 81.451 13.0833C81.3251 13.0833 81.2027 13.0988 81.0859 13.1281L72.6551 6.78839C72.6164 5.9945 71.9604 5.36275 71.1569 5.36275C70.3534 5.36275 69.6975 5.99452 69.6587 6.78842ZM72.6569 10.3958V13.2108C72.6569 13.2904 72.6507 13.3685 72.6388 13.4448L75.9917 15.9661L78.0281 14.4348L72.6569 10.3958ZM69.6569 10.3958L64.2857 14.4348L66.3221 15.9661L69.6751 13.4448C69.6631 13.3685 69.6569 13.2904 69.6569 13.2108V10.3958ZM78.0281 21.1035L72.6569 25.1425V22.1323C72.6569 22.115 72.6566 22.0977 72.656 22.0805L75.9917 19.5722L78.0281 21.1035ZM69.6569 22.1323C69.6569 22.115 69.6572 22.0977 69.6578 22.0805L66.3221 19.5722L64.2857 21.1035L69.6569 25.1425V22.1323ZM79.951 16.5949L78.3895 17.7691L79.951 18.9433V16.5949Z" />
       </a>
       <a href="https://www.linkedin.com/in/alejandromorales95/" target="_blank" rel="noopener noreferrer">
        <path fillRule="evenodd" clipRule="evenodd" d="M34.3137 17.1569C34.3137 26.6323 26.6323 34.3137 17.1569 34.3137C7.68139 34.3137 0 26.6323 0 17.1569C0 7.68139 7.68139 0 17.1569 0C26.6323 0 34.3137 7.68139 34.3137 17.1569ZM8.92154 12.353H13.0392V26.7647H8.92154V12.353ZM19.902 12.353H15.7843V26.7647H19.902V19.0959C19.9799 18.9933 20.0682 18.8987 20.1655 18.8135L20.3186 18.6795C20.8821 18.1866 21.7068 18.1349 22.3274 18.5537C22.7818 18.8603 23.0541 19.3727 23.0541 19.9208V26.7647H27.1696V16.6946C27.1696 16.0947 27.0412 15.5017 26.793 14.9555C25.601 12.3326 22.1834 11.659 20.0853 13.6337L19.902 13.8062V12.353ZM13.7255 8.23532C13.7255 9.75139 12.4965 10.9804 10.9804 10.9804C9.46435 10.9804 8.23533 9.75139 8.23533 8.23532C8.23533 6.71924 9.46435 5.49022 10.9804 5.49022C12.4965 5.49022 13.7255 6.71924 13.7255 8.23532Z" />
       </a>
      </svg>
    </div>
  );
}

export default Footer;
